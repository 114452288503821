<template>
    <div style="background:#eee;padding:60px 50px;height:100%;">
        <Card :bordered="false">
            <p slot="title" style="font-size:20px;line-height:50px;height:50px;text-align:center;color:red;">
                <Icon type="ios-warning" size="30" /> {{ title }}
            </p>

            <div style="margin-top:30px;sline-height:150%;height:200px;text-align:center;">
                <p style="font-size: 16px;">{{ msg }}</p>
                <br /> <br /> <br />
                <a href="javascript:;" @click="goback">
                    <Icon type="ios-arrow-back" size="20" /> 返回上一级
                </a>
                <span style="margin:0 10px;">或</span>
                <a href="javascript:;" @click="checkout">
                    <Icon type="ios-contact" size="20" /> 使用其他账号重新登录
                </a>
            </div>
        </Card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            msg: "你的账号没有访问该功能的权限，如有疑问，请与系统管理员联系。",
            title:"拒绝访问",
        }
    },
    created() {
        var title = this.$core.getUrlParam("title");
        if (title && title != "undefined") this.title = title;
        var msg = this.$core.getUrlParam("msg");
        if (msg && msg != "undefined") this.msg = msg;
        this.$Message.destroy()
    },
    methods: {
        goback() {//返回上一级页面
            var url = top.vue.preUrl;
            top.vue.preUrl = null;
            if (url) top.vue.goto(url);
            else {
                history.go(-2);
            }

        },
        checkout() {//打开登录窗口
            top.vue.checkOut(true);
        }
    }
}
</script>
