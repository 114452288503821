<template>
    <div style="background:#eee;padding:60px 50px;height:100%;">
        <Card :bordered="false">
            <p slot="title" style="font-size:20px;line-height:50px;height:50px;text-align:center;color:red;"><Icon type="md-chatbubbles" size="30"/> 欢迎访问</p>
            
            <div style="margin-top:30px;sline-height:150%;height:200px;text-align:center;font-size:18px;">
                <p>从顶部菜单开始访问您的功能</p>
                 <br/> <br/> <br/>
                 <Button type="success" style="width:150px;" @click="checkout"><Icon type="ios-contact"  size="20"/> 重新登录</Button>
            </div>
        </Card>
    </div>
</template>
<script>
    export default {
        methods: {
            goback(){//返回上一级页面
              this.$parent.goto(top.vue.preUrl);
            },
            checkout(){//打开登录窗口
                top.vue.checkOut(true);
            }
        }
    }
</script>
